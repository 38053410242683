export default({
    methods: {        
        //跳转成就礼包规则页
        gogiftpackRules(){
            this.$router.push("/giftpackRules")
        },
        //回首页
        goHome(){
            let lang = this.$i18n.locale
            // console.log(lang,'..........当前语言')
            let jumpHomepath = {
                'zh-EN': "/",
                'zh-ER': "/ar",
                'zh-TR': "/tr",
            }
            this.$router.push(jumpHomepath[lang])
        }

    }
})