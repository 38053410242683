
<template>
    <div>
        <!-- 这是选择支付方式 -->
        <div class="pay_method">
            <div class="pay_method_item" v-for="(item, key) in payList" :key="key">
                <div class="pay_img_box" @click="switchPaymethod(item)">
                    <img :class="['pay_img', item.ifCheck ? 'active' : 'no_active']" :src="item.payImg" alt="" />
                    <img class="choose_icon" v-if="item.ifCheck" src="../assets/imgs/choose_icon.png" alt="">
                </div>
                <span class="pay_name">{{ item.name }}</span>
            </div>
        </div>
        <!-- 充值币和优惠折叠面板 -->
        <div class="coins_and_offers">
            <div class="title">
                <span class="title-left">{{ $t('m.czcoinsandoffers') }}</span>
            </div>
            <!-- 手风琴模式下：v-model 属性值类型为数字或字符串 
                 非手风琴模式下：v-model 为数组类型-->
            <van-collapse v-model="activeNames" :clickable="false" is-link="false" accordion >
                <van-collapse-item :class="'items_'+item.type.toString()" v-for="(item, index) in coinsandOffersList" :key="index" :name="item.type" >
                    <template #title>
                        <div class="title_part">
                            <div class="titles">
                                <img class="titles_img" :src="item.icon" alt="" />
                                <div class="titles_middle">
                                    <div class="pay_name_box">
                                        <span class="payName">{{ item.name }} 
                                            <img @click="gogiftpackRules" class="rule_icon" v-if="item.type === 2" src="../assets/imgs/help_icon.png" alt="">
                                        </span>
                                    </div>
                                    <div :class="['tips subheading', ifRtl]" >
                                        <div :class="['text', 'text_'+(k+1)]" v-for="(val, k) in item.tips" :key="k" >
                                            <span v-html="val" @click="gotoGiftbuyRecords(k)"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="icon_part">
                            <van-icon
                                class="collapse-icon"
                                :name="isCollapse ? 'arrow-up' : 'arrow-down'"
                            />
                        </div>
                    </template>
                    <!-- 普通coins的子集 -->
                    <div class="recNumList" v-if="item.type === 1">
                        <ul>
                            <!-- 当前有正在进行中的礼包 curuserbuyInfo.user_buy_info.is_status: 0-正在进行 1-已完成 2-已过期   -->
                            <li class="gift_pack_info_box" v-if="!utils.isEmptyObject(curuserbuyInfo.user_buy_info) && !djsIsover">
                                <img src="../assets/imgs/gift.png" alt="" class="gift_img">
                                <div :class="[lang===2?'gift_pack_info_er':'gift_pack_info']">
                                    <div :class="['name', lang === 2 ? 'text_r' : 'text_l']">{{ $t('m.giftpackinprogress', {n: curuserbuyInfo.objective + ' USD '}) }}</div>
                                    <div :class="['need_recharge', lang === 2 ? 'text_r' : 'text_l']">{{ $t('m.offerneed') }} 
                                        <span class="gift_num">
                                            {{ 
                                                curuserbuyInfo.user_buy_info.is_status === 0 && utils.checkTokenauth(userInfo) === 1 ?
                                                curuserbuyInfo.user_buy_info.rate_of_progress + '/' + curuserbuyInfo.objective + ' USD ' : curuserbuyInfo.objective + ' USD '
                                            }}<span @click="gotoCurgiftpageRechargerecords(curuserbuyInfo)" v-if="utils.checkTokenauth(userInfo) === 1"> > </span>&nbsp;
                                        </span>
                                    </div>
                                    <div :class="['recharge_can_get', lang === 2 ? 'text_r' : 'text_l']" v-if="utils.isEmptyObject(curuserbuyInfo.user_buy_info)">{{ $t('m.compltedtask') }} {{ utils.unitChange(curuserbuyInfo, 'coins', 1) }}</div>
                                    <div :class="['recharge_can_get', lang === 2 ? 'text_r' : 'text_l']" v-else>{{ $t('m.compltedtask') }} {{ utils.unitChange(curuserbuyInfo.user_buy_info, 'coins', 1) }}</div>
                                </div>
                                <div class="gift_pack_djs">
                                    <van-count-down class="djs_text" :time="curuserbuyInfo.countdownTime" :format="`DD ${$t('m.days')} HH:mm:ss`" @change="(event)=>{return change(event)}" @finish="finish"/>&nbsp;
                                </div>
                            </li>
                            <!-- 金币充值列表 -->
                            <li v-for="(value, index1) in czTop4Data" :key="index1" class="coins_rechrage_list">
                                <div class="recharge_item">
                                    <span :class="lang === 2 ? 'jinbi_er':'jinbi'">
                                        <template v-if="value.good_id === 'good_19'">
                                            <img class="gc" src="../assets/imgs/icon_task_nor.png" alt="" />
                                        </template>
                                        <template v-else>
                                            <img class="gc" src="../assets/imgs/coins.png" alt="" />
                                        </template>
                                        <div class="coins_box">
                                            <span class="gcnum">                                          
                                                {{ value.g_type === 2 ? $t('m.pass') : value.origin_coins }}
                                                <span class="addCoin" v-if="value.num > 0">+{{ value.num }}</span>
                                            </span>
                                            <span v-if="value.ifRechargecalc === 1 && !djsIsover">{{ $t('m.joingiftpageCalc') }}</span>
                                        </div>
                                    </span>
                                    <!-- <span class="recBtn" @click="value.ifRechargecalc !== 1 ? rechargeCoins(value, item.name) : showConfirmpop(item2, 2, giftItem)"> -->
                                    <span class="recBtn" @click="rechargeCoins(value, item.name)">
                                        {{
                                            currency + " " + value[currency.toLowerCase()]
                                        }}
                                    </span>
                                </div>
                            </li>
                            <!-- 更多充值选项 -->        
                            <div class="recharge_item more_recharge_options" v-if="ifOpenmore">
                                <li v-for="(value, index1) in czAfterTop4data" :key="index1">
                                    <span :class="lang === 2 ? 'jinbi_er':'jinbi'">
                                        <template>
                                            <img class="gc" src="../assets/imgs/coins.png" alt="" />
                                        </template>
                                        <div class="coins_box">
                                            <span class="gcnum">
                                                {{ value.origin_coins }}
                                                <span class="addCoin" v-if="value.num > 0">+{{ value.num }}</span>
                                            </span>
                                            <span v-if="value.ifRechargecalc === 1 && !djsIsover">{{ $t('m.joingiftpageCalc') }}</span>
                                        </div>
                                    </span><span class="recBtn" @click="rechargeCoins(value, item.name)">
                                        {{
                                            currency + " " + value[currency.toLowerCase()]
                                        }}
                                    </span>
                                </li>
                            </div>
                        </ul>
                    </div>    
                    <!-- 展开更多充值选项 -->                
                    <div class="open_more" v-if="item.type === 1">
                        <span class="open_more_text">{{ifOpenmore?$t('m.closeText'):$t('m.openText')}} </span>
                        <span @click="collapseData" :class="['arrow' , 'iconfont', !ifOpenmore ? 'icon-zhankaijiantou-xianxingtubiao-01 unfold' : 'icon-xiangshangjiantou packup ']"></span>
                    </div>
                    <!-- 购买礼包子集 -->
                    <div class="gift_subset" v-if="item.type === 2">
                        <van-tabs v-model="newgiftactiveName" v-if="isFlag">
                            <van-tab v-for="(val1, k1) in giftList" :key="k1" :title="val1.text" :name="val1.name"
                                @change="handlerVantab">
                                <giftSubset v-if="isFlag" 
                                    :djsIsover="djsIsover"
                                    :val1="val1" 
                                    :ifRtl="ifRtl" 
                                    :currency="currency" 
                                    :userInfo="userInfo"
                                    :key="userInfo.user_id"
                                    :differenceTime="differenceTime"
                                    @checkloginfn="checkLogin"
                                    @handlershowRechargePop="triggerFather" ></giftSubset>
                            </van-tab>
                        </van-tabs>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>    
</template>

<script>
import { offerPaymenthistory } from '../assets/js/request'
import jumpFn from '../mixins/jumpFn';
import utilsFn from '../assets/js/commonData';
export default ({
    mixins: [jumpFn],   
    props: {
        lang: {
            type: Number,
            default: 1
        },
        giftactiveName: {
            type: String,
            default: '1'
        },
        currency: {
            type: String,
            default: 'USD'
        },
        payList: {
            type: Array,
            default: []
        },
        activeNamesList: {
            type: Number || stringify,
            default: 1
        },
        coinsandOffersList: {
            type: Array,
            default: []
        },
        czList: {
            type: Array,
            default: []
        },
        giftList: {
            type: Array,
            default: []
        },
        userInfo: {
            type: Object,
            default: {}
        }
        // ifVerify: {
        //     type: Number,
        //     default: 0
        // }
    },
    components: {
        giftSubset: () => import('../components/giftSubsetpanel.vue'),
    },
    data() {
        return {
            djsIsover: false, //倒计时是否结束
            curuserbuyInfo: this.globalData.curUserbuyinfo,  //当前用户购买礼包信息
            isCollapse: false, // 默认为折叠状态
            isFlag: false,            
            activeNames: this.activeNamesList,
            newgiftactiveName: this.giftactiveName,
            payImgs: {
                1: 'coins',
                2: 'offer'
            },
            ifRtl: this.$i18n.locale === 'zh-ER' ? 'rtl text_r' : 'text_l',            
            tipsCol: {
                NaN: 'red',
                undefined: 'red',
                '': 'red',
                0: 'red',
                1: 'haslogin'
            },
            ifVerify: this.globalData.userINFO.ifVerify,
            czTop4Data: [],    //前四项展示的充值数据
            czAfterTop4data: [], //展开更多充值选项数据
            ifOpenmore: false,
            differenceTime: 0, //剩余的倒计时毫秒数
        }
    },
    computed: {
        // newcoinsandOffersList () {
        //     //////console.log(this.coinsandOffersList)
        //     return this.coinsandOffersList
        // }
        // lang () {
        //     let lang = this.$route.query.lang;
        //     return lang ? parseInt(lang) : 1;
        // },
        // ifVerify(){
        //     return Number(sessionStorage.getItem('ifVerify'))
        // }
    },
    watch: {
        // 'newgiftactiveName'(nVal,oVal){
        //     ////////console.log(nVal,oVal)
        // },
        'djsIsover'(nVal,oVal){
            ////console.log(nVal,oVal)
            if(nVal === true) {
                this.djsIsover = true
                this.czList.forEach(item => {
                    item.ifRechargecalc = 0
                })
            }
        },
    },
    created() {        
        console.log('----------------------- 选择支付方式组件created -----------------------------')       
        console.log(this.lang, '.............lang')
        console.log(this.$i18n.locale, '.............i18n的locale')
        console.log(this.ifRtl, '.............ifRtl')
        this.utils.resetLang(this.lang, this) 

        console.log(JSON.parse(JSON.stringify(this.coinsandOffersList)), '..............this.coinsandOffersList')
        console.log(JSON.parse(JSON.stringify(this.userInfo)), '...........userInfo')
        console.log(JSON.parse(JSON.stringify(this.globalData)), '.........globalData')
        console.log(JSON.parse(JSON.stringify(this.curuserbuyInfo)), '.........curuserbuyInfo')        
        // console.log(JSON.parse(JSON.stringify(this.czList)), '.......................czList')

        this.czTop4Data = this.czList.slice(0,4)
        this.czAfterTop4data = this.czList.slice(4, this.czList.length)

        // console.log(JSON.parse(JSON.stringify(this.czTop4Data)), '.......................czTop4Data')
        // console.log(JSON.parse(JSON.stringify(this.czAfterTop4data)), '.......................czAfterTop4data')
        // console.log(JSON.parse(JSON.stringify(this.czList)), '......................截取数据后.czList')

        let ifEmpty = this.utils.isEmptyObject(this.globalData.curUserbuyinfo) || this.utils.isEmptyObject(this.globalData.curUserbuyinfo.user_buy_info)
        if(!ifEmpty) this.globalData.curUserbuyinfo.countdownTime = this.globalData.curUserbuyinfo.user_buy_info.end_time * 1000 - this.globalData.serverTime * 1000  

        this.ifRtl = utilsFn.getRtl(this, this.lang, 1)
        console.log(this.ifRtl, '.............文字对齐方式')

        this.globalData.curreny = this.currency
        this.giftList.forEach(item => {
            item.children[0].forEach(value => {
                this.utils.unitChange(value, 'coins', 1)               
            })
        })
        this.isFlag = true
    },
    methods: { 
        //展开折叠更多充值选项数据
        collapseData(){
            this.ifOpenmore = !this.ifOpenmore
        },  
        //去当前礼包充值记录页面
        gotoCurgiftpageRechargerecords(item){
            ////////console.log(JSON.parse(JSON.stringify(item), '........去当前礼包充值记录页面item'))
            this.$router.push({path: '/giftpackRechargerecords', query: {offer_order_id: item.user_buy_info.offer_order_id}})
        },     
        change(event){
            // ////console.log(event, '..............倒计时change')
            let {days, hours, minutes, seconds} = event
            const s = seconds * 1000
            const m = minutes * 60 * 1000
            const h = hours * 60 * 60 * 1000
            const d = days * 24 * 60 * 60 * 1000
            const totalTime = d + h + m + s
            this.differenceTime = totalTime  //毫秒
            this.djsIsover = days === 0 && hours === 0 && minutes === 0 && seconds === 0 ? true : false
        },
        //倒计时结束
        finish(event){
            ////console.log(event, '..............倒计时结束')
            this.djsIsover = true
            this.differenceTime = 0
        },
        //箭头点击事件
        handleIconClick(event){
            console.log('handleIconClick,,,,,,,,,,', event)
        },
        handleCollapseChange(isCollapse) {
            console.log('............isCollapse', isCollapse)
            if (isCollapse) {
                console.log('折叠面板被展开');
                return false
            } else {
                console.log('折叠面板被折叠');
            }
        },
        //没用上
        resetTips(){
            this.coinsandOffersList[1].tips = this.utils.checkTokenauth(this.userInfo) === 1 ? this.$t('m.rechargeTips') : this.$t('m.nobuygiftTips')
        },
        handlerVantab(event) {
            ////////console.log(event, '.................event')
        },
        switchPaymethod(item) {
            ////////console.log(JSON.parse(JSON.stringify(item)),'..............switchPaymethod')
            this.payList.forEach(item => {
                item.ifCheck = false
            })
            item.ifCheck = true
            this.$emit('handlerChoosefn', item)
        },
        /**
         * @param {*} data  选择的金币item数据
         * @param {*} pay_method  选择的购买类型  礼包或者金币
        */
        rechargeCoins(data, pay_method) {
            console.log('选择支付方式组件的rechargeCoins方法.........', JSON.parse(JSON.stringify(data)), pay_method, )
            let obj = { data, pay_method }
            this.$emit('handlerRecharge', obj)
        },        
        /**
         * @param giftItem   构建订单所需要的参数
         * @param goodsItem  礼包下充值金币的item值
         * @param type 1:买礼包  2：礼包下金币充值
        */
        showConfirmpop(giftItem, type, goodsItem){
            // ////////console.log('购买按钮点击显示的值giftItem.................', JSON.parse(JSON.stringify(giftItem)))
            // ////////console.log('购买按钮点击显示的值goodsItem.................', JSON.parse(JSON.stringify(goodsItem)))
            ////////console.log(this.globalData, '..........globalData')

            let _rid = sessionStorage.getItem('rid')
            if(_rid === "" || this.globalData.rid === "") {
                this.$toast(this.$t('m.nologinTips'))
                return false
            }
            //礼包过期，请刷新页面
            if(giftItem.djsIsover && giftItem.djsIsover !== undefined) {
                this.$toast(this.$t('m.refrsehTips'))
                return false
            }
            //用户账号是否过期
            let _ifExipred = this.utils.checkTokenauth(this.userInfo)
            if(_ifExipred === 0) {
                this.checkLogin()
                return false
            }

            giftItem.rechargeCoinsinfo = {...goodsItem}
            this.$emit('handlershowRechargePop', {val: giftItem, type})
        },

        /**
         * 去礼包购买记录
         * k: 点击范围
        */
        gotoGiftbuyRecords(k){
            if(k === 0) return false;

            //////console.log('去礼包购买记录========================================')
            //////console.log('globalData,,,,,,,', this.globalData)
            //////console.log('userInfo,,,,,,,', this.userInfo)
            //////console.log('k,,,,,,,', k)

            let _ifExipred = this.utils.checkTokenauth(this.userInfo)
            //////console.log('_ifExipred,,,,,,,', _ifExipred)           

            if(this.globalData.rid === ""){
                this.$toast(this.$t('m.nologinTips'))
            }            
            if(_ifExipred === 0 && k === 1) {
                this.$emit('loginCheck', 0, 1)
                return false
            } else {
                if(k === 1) {
                    this.$router.push('/giftpackBuyerecords')
                }
            }            
        },

        async getgiftpackBuyrecords(k){
            let params = {
                uid: this.globalData.uid,
                page: this.page,
                lang: this.lang,
                token: this.globalData.userINFO.token
            }
            ////////console.log(JSON.parse(JSON.stringify(params)), '..........参数')
            await offerPaymenthistory(params).then(res => {
                //////console.log(res, '.............getgiftpackBuyrecords')
                if(res.code === 0) {
                    if(k === 1) this.$router.push('/giftpackBuyerecords')
                } else if(Number(res.code) === 10027) {
                    this.$emit('sendcheckCode', { 
                        lang: this.lang,
                        uid: this.userInfo.user_id,
                        opt: 1
                    })    
                }
            })
        },

        //校验登录
        checkLogin(val){
            //////console.log(val, '........校验登录...............checkLogin')
            this.$emit('loginCheck', val)
        },
        triggerFather(val){
            ////////console.log('触发父级showRechargePop方法...........triggerFather')
            this.$emit('showRechargePop', val)
        }
    }
})
</script>

<style src="@/iconfont/iconfont.css"></style>
<style lang="scss" >
@import '../assets/css/common.scss';
@import '../assets/css/flash.scss';

.unfold {
    animation: unfold 0.5s linear 1;
}
.packup {
    animation: packup 0.5s linear 1;
}
.open_more {
    background-color: #f8f8f8;
    width: 100%;
    height: 48px;
    text-align: center;
    @include flex-cen;
    &_text {
        margin: 0;
    }
    .arrow {
        font-size: 12px;
        padding: 10px;
        cursor: pointer;
    }
}
.red {
    color: #FF4D4F !important;
}
.haslogin {
    color: rgba(51,51,51,0.5) !important;
}

.pay_method {
    width: 100%;
    @include flex-row-left;
    box-sizing: border-box;
    margin-top: 19px;
    background: $themeCol;
    padding: 12px 45px;

    .pay_method_item {
        @include flex-column-top;
        width: calc(100% / 3);

        .pay_img_box {
            position: relative;
            height: 16vw;

            .pay_img {
                width: 100%;
                height: 15vw;
                margin: 0 0 0 0;
                border-radius: 30px;
                opacity: 1;
                border: 1px solid #C5C6C7;
                transform: scale(0.7);
            }

            .active {
                border-radius: 30px;
                opacity: 1;
                border: 1px solid #3C4043;
            }

            .choose_icon {
                width: 16px;
                height: 16px;
                position: absolute;
                right: 15px;
                top: 5px;
            }
        }

        .pay_name {
            font-size: 10px;
            color: #333;
            display: inline-block;
            width: 100%;
            text-align: center;
            flex-wrap: nowrap;
            margin: 0 0 0 0;
        }

        .ico {
            width: 5vw;
        }
    }
}

.coins_and_offers {
    padding: 24px 0 16px 0;

    .titles {
        .titles_img {
            width: 32px;
            height: 32px;
            margin: 0 10px;
        }

        .titles_middle {
            .pay_name_box {
                .payName {
                    font-size: 14px;
                    font-weight: bold;
                    color: #333333;
                    line-height: 16px;
                    text-align: left !important;
                    @include flex-row-left;

                    .rule_icon {
                        width: 16px;
                        height: 16px;
                        margin: 0 8px;
                    }
                }
            }

            .tips {
                .text {
                    font-size: 12px;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 0.5);
                    line-height: 15px;
                    border: 1px solid transparent;
                }
                .text:last-child {
                    display: inline-block;
                }
            }
        }
    }

    .van-collapse {
        .van-collapse-item {
            .van-cell {
                .van-cell__title {
                    @include flex-between;
                    .title_part {
                        flex: 7;
                        border: 1px solid transparent;
                    }
                    .icon_part {          
                        flex: 1;                      
                        border: 1px solid transparent;
                    }
                }
                // .van-icon {                    
                //     border: 1px solid blue;
                // }
                .van-cell__right-icon {
                    display: none;
                }
            }
            .van-collapse-item__wrapper {
                .van-collapse-item__content {
                    padding: 0 !important;
                    .van-tabs__wrap {
                        .van-tabs__nav {
                            .van-tabs__line {
                                width: 10px;
                                height: 3px;
                                background: linear-gradient(270deg, #FB6C90 0%, #FF9B67 100%);
                                border-radius: 2px 2px 2px 2px;
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    //coins子集
    .recNumList {
        .gift_pack_info_box {
            padding: 8px 16px !important;
            .gift_img {
                width: 32px;
                height: 32px;
            }
            .gift_pack_info, .gift_pack_info_er {
                .name {
                    font-size: 14px;
                    font-weight: 600;
                    color: #333333;
                    -webkit-background-clip: text;
                }
                .need_recharge {
                    .gift_num {
                        font-size: 12px;
                        color: #000;
                    }
                }
            }
            .gift_pack_info {                
                padding-left: 10px;
            }
            .gift_pack_info_er {                
                padding-right: 10px;
            }
            .gift_pack_djs {
                padding: 0 10px;
                box-sizing: border-box;
            }
        }
        .jinbi, .jinbi_er {
            .coins_box {
                width: 50vw;
                @include flex-column-start;
                span {
                    width: 100%;
                }
            }
        }
        .jinbi {
            span {
                text-align: left;
                width: 100%;
            }
        }
        .jinbi_er {
            span {
                text-align: right;
                width: 100%;
            }
        }
    }

    //礼包子集
    .gift_subset {
        .gift_name {
            @include flex-row-left;
            margin-bottom: 10px;

            img {
                width: 32px;
                height: 32px;
                // margin: 0 12px 0 0;
            }

            .name {
                line-height: 32px;
            }
        }
        .recharge_row {
            display: flex;
            text-wrap: wrap;
            .text {
                @include flex-row-top;
                font-size: 12px;
                font-weight: 400;
                color: #333;
                .has_buy {         
                    height: 3vh;      
                    .frozentime {
                        width: 30vw;
                    }
                    .van-count-down {
                        line-height: 23px;
                    }
                }
            }

            .num {
                font-size: 12px;
                color: #000;
                .has_buy {
                    @include flex-cen;
                    
                    .frozentime {
                        width: 30vw;
                    }
                    .van-count-down {
                        line-height: 23px;
                    }
                }
            }
        }

        //礼包下金币部分
        .van-collapse-item {
            .van-cell {
                .van-cell__title {
                    @include flex-column-top;    
                    .gift_subset_top {       
                        @include flex-between;
                        width: 100%;
                        .gift_subset_titles {
                            width: 65%;
                        }
                        .gift_subset_titles_right {
                            @include flex-around;
                            border: 1px solid #333333;
                            width: 35%;
                            min-height: 48px;
                            height: auto;
                            opacity: 1;
                            border-radius: 2px 2px 2px 2px;
                            .no_login {
                                width: 85%;
                                font-size: 13px;
                                font-weight: 600;
                                color: #333333;
                                line-height: 15px;
                            }
                            .opt_part {
                                @include flex-cen;
                            }
                            .opt_part_nobuy, .opt_part_forzen {
                                @include flex-column-cen;
                                padding: 7px;
                                span:first-child {
                                    font-size: 13px;
                                    font-weight: bold;
                                    color: #333333;
                                    line-height: 18px;
                                }
                                span:last-child {
                                    font-size: 12px;
                                    font-weight: 400;
                                    color: #333333;
                                    line-height: 14px;
                                }
                            }
                        }
                    }   
                }
                .van-cell__right-icon {
                    display: none;
                }

            }
        }
        // .van-tabs {
        //     .van-tabs__wrap {
        //         .van-tabs__nav {
        //             .van-tab {
        //                 .van-tab--active {
        //                     font-weight: bold !important;
        //                     border: 1px solid red;
        //                 }
        //             }
        //         }
        //     }
        // }
        .van-tab--active {
            .van-tab__text--ellipsis {                
                font-weight: bold !important;
            }
        }
        .van-tab  {
            font-weight: 200 !important;
        }
    }
}
</style>
